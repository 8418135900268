<template>
  <Swiper
    data-component-name="FeedbackSlider"
    :modules="[Pagination]"
    :pagination="{ clickable: true }"
    :breakpoints="{
      1279: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerView: 'auto',
        spaceBetween: 32,
        centeredSlides: true,
      },
      767: {
        slidesOffsetBefore: 32,
        slidesOffsetAfter: 32,
        slidesPerView: 1.1,
        spaceBetween: 24,
        centeredSlides: false,
      },
      0: {
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
        slidesPerView: 1.1,
        spaceBetween: 8,
        centeredSlides: false,
      },
    }"
  >
    <SwiperSlide
      v-for="feedback of feedbacks"
      v-slot="{ isActive }"
      :key="feedback.link"
    >
      <FeedbackCard :active="isActive" v-bind="omit(feedback, 'img')">
        <NuxtImg
          v-if="feedback.img"
          v-bind="feedback.img"
          densities="x1 x2"
          format="webp"
        />
      </FeedbackCard>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { useLocalePath, useI18n } from '#i18n';

// swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// components
import FeedbackCard from '~/components/redesign/FeedbackCard.vue';

// utils
import omit from 'lodash.omit';

// constants
import { PATHS } from '~/constants';

const { t } = useI18n();
const localePath = useLocalePath();

const feedbacks = [
  {
    link: localePath(PATHS.CASE_STUDIES.path + 'centerbase-reinforces-data-protection-strategy-with-object-first'),
    quote: t('FeedbackSlider.slide-1.quote'),
    author: {
      name: 'Zach Young',
      position: t('FeedbackSlider.slide-1.author.position'),
    },
    img: {
      src: '/redesign/images/partners/Centerbase_color.webp',
      alt: 'Centerbase logo',
      width: 223,
      height: 52,
    },
  },
  {
    link: localePath(PATHS.CASE_STUDIES.path + 'immutability-out-of-the-box-solved-for-mirazon-and-their-customers'),
    quote: t('FeedbackSlider.slide-2.quote'),
    author: {
      name: 'Brent Earls',
      position: t('FeedbackSlider.slide-2.author.position'),
    },
    img: {
      src: '/redesign/images/partners/Mirazon_color.webp',
      alt: 'Mirazon logo',
      width: 243,
      height: 50,
    },
  },
  {
    link: localePath(PATHS.CASE_STUDIES.path + 'prodatix-introduces-on-prem-immutable-storage-for-veeam-with-object-first'),
    quote: t('FeedbackSlider.slide-3.quote'),
    author: {
      name: 'Matt Bullock',
      position: t('FeedbackSlider.slide-3.author.position'),
    },
    img: {
      src: '/redesign/images/partners/Prodatix_color.webp',
      alt: 'Prodatix logo',
      width: 216,
      height: 50,
    },
  },
  {
    link: localePath(PATHS.CASE_STUDIES.path + 'university-ransomware-proofs-backups-with-ootbi-by-object-first'),
    quote: t('FeedbackSlider.slide-4.quote'),
    author: {
      name: 'Manager',
      position: t('FeedbackSlider.slide-4.author.position'),
    },
    img: null,
  },
] as const;
</script>

<style scoped lang="scss">
@import "$/mixins/media";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/common";
@import "$/functions/token";
@import "$/variables/shadows";
@import "$/mixins/components";

[data-component-name="FeedbackSlider"] {
  &:deep(.swiper-wrapper) {
    padding: 5rem 0 5.8rem 0;

    @include tablet {
      padding: 4rem 0 5.8rem 0;
    }

    @include mobile {
      padding: 2rem 0 5.8rem 0;
    }

    .swiper-slide {
      max-width: 76rem;
      width: 100%;
      height: auto;

      display: flex;
    }
  }

  &:deep(.swiper-pagination) {
    @include swiper-pagination;

    @include tablet {
      transform: translateY(-1.5rem);
    }

    @include mobile {
      transform: translateY(-2.5rem);
    }
  }
}
</style>
